import React from "react";

const Slider = () => {
    return(
        <>
       {/* <div className="slider">
            <div className="item" id="item1">FIRST</div>
            <div className="item" id="item2">SECOUND</div>
            <div className="item" id="item3">THIRD</div>
        </div>
        
        <div className="link">
        <a href="#item1">1</a>
        <a href="#item2">2</a>
        <a href="#item3">3</a>
        </div>*/}
        </>
    );
}; 

export default Slider;