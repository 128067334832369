import React from "react";

const FormInput = ({
  iconType,
  inputMargin,
  onChange,
  isOtp,
  onClick,
  otpMargin,
  border,
  ...props
}) => {
  return (
    <div className={`input-div  ${inputMargin}` +  (border==='danger' ? ' border-3 border-danger ' : '')}>
      <div className="i">
        <i className={`fas ${iconType}`} />
      </div>
      <div className="div">
        <input className="input" {...props} onChange={onChange} />
        {isOtp ? (
          <div className={`otp-btn ${otpMargin}`} onClick={onClick}>
            Resend
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FormInput;
