import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import changeTheme from "../../redux/actions/theme";

const ThemeToggle = ({fontSize, className}) => {
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const app = window.document.getElementById("app");
    if (theme === "light") {
      app.classList?.remove("bg-dark");
      localStorage.setItem("mainsiasTheme", theme);
    }
    if (theme === "dark") {
      app.className = "bg-dark";
      localStorage.setItem("mainsiasTheme", theme);
    }
  }, [theme]);

  return (
    <>
      <p id="themeToggle" className={className} style={{ fontSize: fontSize, padding: "6px 0", margin: 0 }}>
      <i className="fa-solid fa-sun"></i>{" "}
        {theme === "light" ? (
          <i
            onClick={() => dispatch(changeTheme("dark"))}
            className="fa-solid fa-toggle-off"
            style={{cursor: "pointer"}}
          ></i>
        ) : (
          <i
            onClick={() => dispatch(changeTheme("light"))}
            className="fa-solid fa-toggle-on text-white"
            style={{cursor: "pointer"}}
          ></i>
        )}{" "}
        <i className="fa-solid fa-moon"></i>
      </p>
    </>
  );
};

export default ThemeToggle;
