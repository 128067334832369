import React from "react";
import { useHistory } from "react-router";
import { FaBackward } from "react-icons/all";
import "./HeaderButton.css";
import ThemeToggle from "../../../components/ThemeToggle/ThemeToggle";

const HeaderButton = ({ btnName, hideButton, backButton, onClick }) => {
  const history = useHistory();
  return (
    <div className="d-flex  col-md-12  justify-content-between mr-5">
      <a
        className="navbar-brand logo cursor-pointer"
        onClick={() => history.push("/")}
      >
        <img
          src="/mainsIASLogo.jpeg"
          in
          alt=""
          style={{ width: "50px" }}
          className="logo-dark"
          height={24}
        />
        <span className="px-2 text-dark">MainsIAS</span>
      </a>
      <span className="d-flex">
        <ThemeToggle fontSize="20px" className="justify-content-center align-items-center" />
        {!hideButton && (
          <p
            id="headerBtn"
            className="border px-4 py-2 border border-success rounded-pill text-dark cursor-pointer sign-btn"
            onClick={onClick}
          >
            {btnName}
          </p>
        )}

        {backButton && (
          <p
            className="border px-4 py-2 border border-success rounded-pill text-dark cursor-pointer sign-btn"
            onClick={onClick}
          >
            <FaBackward /> {btnName}
          </p>
        )}
      </span>
    </div>
  );
};

export default HeaderButton;
