export function apiDomainName() {
  if (window.location.hostname === "localhost") return "http://localhost:8000/";
  else return `https://${window.location.hostname}/api/`;
}

export function mediaDomainName() {
  if (window.location.hostname === "localhost") return "http://localhost:8000";
  else return `https://${window.location.hostname}:8000`;
}

export function siteDomainName() {
  if (window.location.hostname === "localhost") return "http://localhost:3000/";
  else return `https://${window.location.hostname}/`;
}

export function websocketDomainName() {
  if (window.location.hostname === "localhost")
    return "ws://localhost/websocket/";
  else return `wss://${window.location.hostname}/websocket/`;
}

export const mediaPath = "";
// export const BASE_API_URL = `https://api.${window.location.hostname}/api`;
// http://9ca7-103-68-23-156.ngrok.io
// export const BASE_API_URL = `https://mainsias.herokuapp.com/api`;
// export const BASE_API_URL  = `https://1472-2409-4042-4cc9-17c6-184e-525-9b2b-7a8.ngrok.io/api`
export const BASE_API_URL = "https://back.mainsias.com/api";
//  export const BASE_API_URL=`http://localhost:8000/api`

