import AuthActionTypes from "../actionTypes/auth";
import {
  updateCanCreateSubscription,
  updateProfile,
  verifiedCurrentUser,
} from "../utils/auth";

const INITIAL_STATE = {
  currentUser: null,
  isRedirect: false,
  isLoading: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.AUTH_SIGN_IN_START:
    case AuthActionTypes.AUTH_SIGN_UP_START:
    case AuthActionTypes.OTP_VERIFY_START:
    case AuthActionTypes.OTP_RESEND_START:
    case AuthActionTypes.UPDATE_PROFILE_START:
    case AuthActionTypes.USER_DELETE_START:
    case AuthActionTypes.SOCIAL_SIGN_UP_START:

      return {
        ...state,
        isLoading: true,
        isRedirect: false,
      };

    case AuthActionTypes.AUTH_SIGN_UP_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        isRedirect: false,
        isLoading: false,
      };

    
    case AuthActionTypes.SOCIAL_SIGN_UP_SUCCESS:
        return {
          ...state,
          currentUser: action.payload,
          isRedirect: false,
          isLoading: false,
        };

    case AuthActionTypes.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        isLoading: false,
      };

    case AuthActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: updateProfile(state.currentUser, action.payload),
      };

    case AuthActionTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        isLoading: false,
      };

    case AuthActionTypes.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        currentUser: verifiedCurrentUser(state.currentUser, action.payload),
        isRedirect: true,
        isLoading: false,
      };

    case AuthActionTypes.OTP_RESEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActionTypes.USER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
      };

    case AuthActionTypes.GET_CAN_CREATE_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        currentUser: updateCanCreateSubscription(
          state.currentUser,
          action.payload
        ),
      };

    case AuthActionTypes.USER_DELETE_REDIRECT:
      return {
        ...state,
        isRedirect: false,
      };

    case AuthActionTypes.AUTH_SIGN_UP_FAILURE:
    case AuthActionTypes.AUTH_SIGN_IN_FAILURE:
    case AuthActionTypes.AUTH_LOGOUT_FAILURE:
    case AuthActionTypes.OTP_VERIFY_FAILURE:
    case AuthActionTypes.OTP_RESEND_FAILURE:
    case AuthActionTypes.UPDATE_PROFILE_FAILURE:
    case AuthActionTypes.USER_DELETE_FAILURE:
    case AuthActionTypes.SOCIAL_SIGN_UP_FAILURE:

      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
