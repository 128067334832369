export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function isSubscriptonExpired(expiryDate) {
  let currentDate = new Date()
    .valueOf()
    .toLocaleString("en-US", { timeZone: "Asia/Kolkata" });

  let expriedDate = new Date(expiryDate)
    .valueOf()
    .toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
  let isExpired =
    parseFloat(expriedDate.replace(/,/g, "")) -
      parseFloat(currentDate.replace(/,/g, "")) >
    0
      ? false
      : true;
  return {
    isExpired,
    expiryColor: isExpired > 0 ? "danger" : "success",
  };
}

export const AuthPathCheck = (locationPath) => {
  const authPath = ["/admin", "/manager", "/evaluator", "/student"];
  let isAuthPath = authPath.some((path) => locationPath.includes(path));

  return isAuthPath; // return true or false
};

export const isFileAccept = (file) => {
  let fileExtenstionSplit = file.split(".")
  let fileExtenstion = fileExtenstionSplit[fileExtenstionSplit.length - 1]
  const validExtention = ["jpeg","jpg","bmp","pdf","png","docx","pptx", "doc", "ppt"]
  return validExtention.some(ext => ext.includes(fileExtenstion) )
}
