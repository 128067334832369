import ThemeActionType from "../actionTypes/theme";

const changeTheme = (theme) => {
  return {
    type: "CHANGETHEME",
    payload: theme,
  };
};

export default changeTheme;
