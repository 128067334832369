import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../redux/selectors/auth";

import path from "./path";
import Loader from "../components/loader/Loader";
import SignIn from "../containers/SignInAndSignUp/SignIn"
import SignUp from "../containers/SignInAndSignUp/SignUp"

const Home = lazy(() => import("../containers/Home"));
// const SignIn = lazy(() => import("../containers/SignInAndSignUp/SignIn"));
// const SignUp = lazy(() => import("../containers/SignInAndSignUp/SignUp"));
const PromotionalMail = lazy(() => import("../containers/Dashboard/Admin/PromotionalMail"));
const PromotionalMailConfirmation = lazy(() => import("../containers/Dashboard/Admin/PromotionalMail/PromotionalMailConfirmation"));
const Dashboard = lazy(() => import("../containers/Dashboard/Admin/Home"));
const Otp = lazy(() => import("../containers/SignInAndSignUp/Otp"));
const ForgotPassword = lazy(() =>
  import("../containers/SignInAndSignUp/ForgotPassword")
);
const CreateManager = lazy(() =>
  import("../containers/Dashboard/Admin/Manager/CreateManager")
);
const Manager = lazy(() => import("../containers/Dashboard/Admin/Manager"));
const EditManager = lazy(() =>
  import("../containers/Dashboard/Admin/Manager/EditManager")
);
const TeacherPage = lazy(() => import("../pages/Admin/Teacher/TeacherPage"));
const TeacherSummary = lazy(() =>
  import("../containers/Dashboard/Teacher/Summary")
);
const CreateTeacher = lazy(() =>
  import("../containers/Dashboard/Admin/Teacher/CreateTeacher")
);
const Exam = lazy(() => import("../containers/Dashboard/Admin/Exam"));
const CreateExam = lazy(() =>
  import("../containers/Dashboard/Admin/Exam/CreateExam")
);
const ExamDetailsPage = lazy(() =>
  import("../pages/Admin/Exam/ExamDetails/ExamDetailsPage")
);
const StudentHome = lazy(() => import("../containers/Dashboard/Student/Home"));
const StudentExam = lazy(() => import("../containers/Dashboard/Student/Exam"));
const StudentExamDetailsPage = lazy(() =>
  import("../pages/Student/Exam/ExamDetails/ExamDetailsPage")
);
const PaidSubscriptionDetails = lazy(() =>
  import("../containers/Dashboard/Student/Home/PaidSubscriptionDetails")
);
const MySubscriptions = lazy(() =>
  import("../containers/Dashboard/Student/MySubscriptions")
);
const MyAnswers = lazy(() =>
  import("../containers/Dashboard/Student/MyAnswers")
);
const Timer = lazy(() =>
  import("../containers/Dashboard/Student/Timer/Index")
);
const AnswerDetails = lazy(() =>
  import("../containers/Dashboard/Student/MyAnswers/Details")
);
const TeacherHome = lazy(() => import("../containers/Dashboard/Teacher/Home"));
const Request = lazy(() => import("../containers/Dashboard/Admin/Request"));
const Profile = lazy(() => import("../containers/Dashboard/Profile"));
const StudentInfo = lazy(() =>
  import("../containers/Dashboard/Admin/SearchStudent/StudentInfo")
);
const SearchStudent = lazy(() =>
  import("../containers/Dashboard/Admin/SearchStudent")
);
const TeacherGlance = lazy(() =>
  import("../containers/Dashboard/Teacher/Glance")
);

const TermsAndConditon = lazy(() => import("../containers/TermsAndCondition"));

const Routes = ({ currentUser }) => (
  <Switch>
    <Suspense fallback={<Loader />}>
      <Route exact path={path.SIGN_IN} component={SignIn} />
      <Route exact path={path.SIGN_UP} component={SignUp} />
      <Route exact path={path.OTP} component={Otp} />
      <Route exact path={path.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={path.INDEX} component={Home} />
      <Route exact path={path.INDEX_NAME} component={Home} />

      <Route exact path={path.TERM_N_CONDITIONS} component={TermsAndConditon} />

      {currentUser &&
        currentUser.user.isVerified &&
        currentUser.user.isStudent ? (
        <>
          <Route exact path={path.STUDENT_DASHBOARD} component={StudentHome} />
          <Route exact path={path.STUDENT_Timer} component={Timer} />
          <Route exact path={path.STUDENT_EXAM_LIST} component={StudentExam} />
          <Route
            path={path.STUDNET_EXAM_DETAILS}
            component={StudentExamDetailsPage}
          />
          <Route
            path={path.STUDENT_PAID_SUBSCRIPTION_DETAILS}
            component={PaidSubscriptionDetails}
          />
          <Route
            path={path.STUDENT_SUBSCRIPTIONS}
            component={MySubscriptions}
          />
          <Route exact path={path.STUDENT_ANSWERS} component={MyAnswers} />
          <Route
            exact
            path={path.STUDENT_ANSWERS_DETAILS}
            component={AnswerDetails}
          />
          <Route exact path={path.STUDENT_PROFILE} component={Profile} />
        </>
      ) : (
        ""
      )}

      {currentUser &&
        currentUser.user.isVerified &&
        currentUser.user.isAdmin ? (
        <>
          <Route exact path={path.ADMIN_DASHBOARD} component={Dashboard} />
          <Route exact path={path.CREATE_MANAGER} component={CreateManager} />
          <Route exact path={path.EDIT_MANAGER} component={EditManager} />
          <Route exact path={path.MANAGER} component={Manager} />
          <Route exact path={path.CREATE_TEACHER} component={CreateTeacher} />
          <Route path={path.TEACHER} component={TeacherPage} />
          <Route exact path={path.CREATE_EXAM} component={CreateExam} />
          <Route exact path={path.EXAM} component={Exam} />
          <Route path={path.EXAM_DETAILS} component={ExamDetailsPage} />
          <Route path={path.REQUEST} component={Request} />
          <Route exact path={path.ADMIN_STUDENT_INFO} component={StudentInfo} />
          <Route exact path={path.PROMOTIONAL_MAIL} component={PromotionalMail} />
          <Route exact path={path.PROMOTIONAL_MAIL_CONFIRMATION} component={PromotionalMailConfirmation} />
          <Route
            exact
            path={path.ADMIN_STUDENT_SEARCH}
            component={SearchStudent}
          />
        </>
      ) : (
        ""
      )}

      {currentUser &&
        currentUser.user.isVerified &&
        currentUser.user.isTeacher ? (
        <>
          <Route exact path={path.TEACHER_DASHBOARD} component={TeacherHome} />
          <Route exact path={path.TEACHER_PROFILE} component={Profile} />
          <Route exact path={path.TEACHER_GLANCE} component={TeacherGlance} />
          <Route exact path={path.TEACHER_SUMMARY} component={TeacherSummary} />
        </>
      ) : (
        ""
      )}

      {currentUser &&
        currentUser.user.isVerified &&
        currentUser.user.isManager ? (
        <>
          <Route exact path={path.MANAGER_DASHBOARD} component={Dashboard} />
          <Route exact path={path.MANAGER_CREATE_EXAM} component={CreateExam} />
          <Route exact path={path.MANAGER_EXAM} component={Exam} />
          <Route
            path={path.MANAGER_EXAM_DETAILS}
            component={ExamDetailsPage}
          />{" "}
          <Route exact path={path.MANAGER_PROFILE} component={Profile} />
        </>
      ) : (
        ""
      )}
    </Suspense>
  </Switch>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(Routes);
