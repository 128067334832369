const initialTheme = localStorage.getItem("mainsiasTheme") || "light";

const changeTheme = (state = initialTheme, action) => {
  switch (action.type) {
    case "CHANGETHEME":
      return (state = action.payload);
    default:
      return state;
  }
};

export default changeTheme;
