export const verifiedCurrentUser = (currentUser, result) => {
  currentUser.user.isVerified = result.result;
  currentUser.token = result.token
  return currentUser;
};

export const updateProfile = (currentUser, profile) => {
  currentUser.user.first_name = profile.first_name;
  currentUser.user.last_name = profile.last_name;
  currentUser.user.gender = profile.gender;
  currentUser.user.phone = profile.phone;
  return currentUser;
};

export const updateCanCreateSubscription = (
  currentUser,
  canCreateSubscription
) => {
  currentUser.user.can_create_subscription = canCreateSubscription;
  return currentUser;
};
