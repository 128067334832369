import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { Avtar, Bg } from "../icons/index";
import wave from "../img/wave.png";
import FormInput from "../components/FormInput";
import HeaderButton from "../components/HeaderButton";
import "../style.css";
import { signInStart, SocialsignUpStart } from "../../../redux/actions/auth";
import {
  selectAuthLoading,
  selectCurrentUser,
} from "../../../redux/selectors/auth";
import Loader from "../../../components/loader/Loader";
import { toast } from "react-toastify";
import { sidebarOptionChange } from "../../../redux/actions/sidebar";
import { GoogleLogin } from 'react-google-login';

import Slider from "../Carousel/Slider";
import ThemeToggle from "../../../components/ThemeToggle/ThemeToggle";

const SignIn = ({
  signInStart,
  currentUser,
  isLoading,
  location,
  sidebarOptionChange,
  SocialsignUpStart,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    if (!email) return toast.error("Please Enter an Email");
    if (!password) return toast.error("Please Enter an password");
    e.preventDefault();

    signInStart(email, password);
  };

  const responseGoogle=(response)=>{
    if(response.tokenObj.id_token){
      SocialsignUpStart(response.tokenObj.id_token)
    }
  }

  const history = useHistory();

  if (currentUser) {
    console.log(currentUser)
    if (currentUser && !currentUser.user.isVerified) {
      history.push("/otp");
    }

    if (
      currentUser &&
      currentUser.user.isVerified &&
      currentUser.user.isAdmin
    ) {
      sidebarOptionChange("dashboard", "main", "admin");

      history.push("/admin/dashboard");
    }

    if (
      currentUser &&
      currentUser.user.isVerified &&
      currentUser.user.isStudent
    ) {
      sidebarOptionChange("studentHome", "main", "student");
      if (location.state) history.push(location.state);
      else history.push("/student/dashboard");
    }

    if (
      currentUser &&
      currentUser.user.isVerified &&
      currentUser.user.isManager
    ) {
      sidebarOptionChange("managerDashboard", "main", "manager");

      history.push("/manager/dashboard");
    }
  }

  if (
    currentUser &&
    currentUser.user.isVerified &&
    currentUser.user.isTeacher
  ) {
    sidebarOptionChange("teacherDashboard", "main", "teacher");

    history.push("/evaluator/dashboard");
  }

  return (
    <div>
      {isLoading ? <Loader /> : ""}
      {/* <img className="wave" src={wave} alt="wave" /> */}
      <HeaderButton
        btnName="Sign Up"
        onClick={() => history.push("/auth/sign-up")}
      />
      <div className="row col-md-12">
        <div className="form-container col-md-12">
          <div className="img">
            {/* <Bg /> */}
            {/* <img src="/img/study.svg" width="500px" alt="" /> */}
          </div>
          <div className="login-content col-md-12">
            <form
              className="sign-in-and-sign-up-form col-md-12"
              onSubmit={handleSubmit}
            >
              {/* <Avtar className="avtar-form" /> */}
              {/* <img src="/logo.png" alt="" className="avtar-form" /> */}
              <img src="/mainsIASLogo.jpeg" alt="" className="avtar-form mb-2" 
              style={{"height":"170px"}}/>
              <br></br>
              <GoogleLogin
                clientId="806236773661-h9u47gufcshp8d2p5fq9v9lio4ig9en2.apps.googleusercontent.com"
                buttonText="Sign In with Google"
                onSuccess={responseGoogle}
                onFailure={(err)=>{console.log("errorInOAuth",err)}}
                cookiePolicy={'single_host_origin'}
              />
              <h2 className="title avtar-form">Sign In</h2>
              <div className="col-md-12">
                <FormInput
                  inputMargin="mt-0 mr-2"
                  iconType="fa-user"
                  type="email"
                  value={email}
                  placeholder="Email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />

                <FormInput
                  inputMargin="mb-1 mr-2"
                  iconType="fa-lock"
                  type="password"
                  value={password}
                  placeholder="Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <a
                  id="forgotButton"
                  className="mr-3 cursor-pointer"
                  onClick={() => history.push("/auth/forgot-password")}
                  style={{ textDecoration: "none" }}
                >
                  Forgot Password?
                </a>
              </div>

              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn form-submit-btn mb-3"
                  disabled={isLoading ? true : false}
                >
                  Sign In
                </button>
              </div>
              {/* <GoogleLogin
                clientId="580293157686-csvpu7qbah12asm44pbu33sv208n22dk.apps.googleusercontent.com"
                buttonText="Sign In with Google"
                onSuccess={responseGoogle}
                onFailure={(err)=>{
                  console.log(err)
                }}
                cookiePolicy={'single_host_origin'}
              /> */}
          </form>
         
          </div>
        </div>
      </div> 
      <Slider/>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isLoading: selectAuthLoading,
});

const mapDispatchToProps = (dispatch) => ({
  signInStart: (email, password) => dispatch(signInStart({ email, password })),
  sidebarOptionChange: (selectedOption, setOptions, userType) =>
    dispatch(sidebarOptionChange({ selectedOption, setOptions, userType })),
  SocialsignUpStart: (auth_token)=>
    dispatch(SocialsignUpStart({auth_token}))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
