import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import axios from 'axios'
import { Avtar, Bg } from '../icons/index'
import wave from '../img/wave.png'
import FormInput from '../components/FormInput'
import HeaderButton from '../components/HeaderButton'
import ScaleLoader from 'react-spinners/ClipLoader'
import { signUpStart, SocialsignUpStart } from '../../../redux/actions/auth'
import { BASE_API_URL } from '../../../services/domain'
import '../style.css'
import {
  selectAuthLoading,
  selectCurrentUser,
} from '../../../redux/selectors/auth'
import Loader from '../../../components/loader/Loader'
import { toast } from 'react-toastify'
import { toTitleCase } from '../../../utils/utils'
import { GoogleLogin } from 'react-google-login'
import { WiMoonAltWaxingGibbous6 } from 'react-icons/wi'
import { TiArrowMaximiseOutline } from 'react-icons/ti'
import { css } from '@emotion/react'

const SignUp = ({ signUpStart, currentUser, isLoading, SocialsignUpStart }) => {
  const history = useHistory()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [auth_token, setAuthToken] = useState('')
  const [EmailCheck, setEmailCheck] = useState('')
  let [color, setColor] = useState('success')
  let [loading, setLoading] = useState(false)
  const responseGoogle = (response) => {
    // setAuthToken(response.tokenObj.id_token)
    // console.log(auth_token)
    SocialsignUpStart(response.tokenObj.id_token)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      axios
        .post(`${BASE_API_URL}/auth/check/email_availability`, { email })
        .then((res) => {
          if (email === '') {
            setEmailCheck('')
            setColor('')
          }
          else if (
            !email.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )
          ) {
            setEmailCheck('Not Valid email')
            setColor('danger')
          } else {
            if (res.data.result === 'email not available') {
              setEmailCheck('Email Already in use')
              setColor('danger')
            } else {
              setEmailCheck('Available')
              setColor('success')
            }
          }
        })
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [email, EmailCheck])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!firstName) toast.error('Please Enter an First Name')
    if (!lastName) toast.error('Please Enter an Last Name')
    if (!email) toast.error('Please Enter an email')
    if (!password) toast.error('Please Enter an password')
    if (password.length < 6)
      return toast.error('Minimum password length should be 6')

    signUpStart(
      toTitleCase(firstName),
      toTitleCase(lastName),
      email.toLowerCase(),
      password,
    )
  }

  if (currentUser) {
    if (currentUser && !currentUser.user.isVerified) {
      history.push('/otp')
    }

    if (currentUser && currentUser.user.isVerified) {
      history.push('/')
    }
  }

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `

  return (
    <div>
      {isLoading ? <Loader /> : ''}
      <img className="wave" src={wave} alt="wave" />
      <HeaderButton
        btnName="Sign In"
        onClick={() => history.push('/auth/sign-in')}
      />
      <div className="row col-md-12">
        <div className="form-container col-md-12">
          <div className="img">{/* <Bg /> */}</div>
          <div className="login-content col-md-12">
            <form
              className="sign-in-and-sign-up-form col-md-12 mb-3"
              onSubmit={handleSubmit}
            >
              {/* <Avtar className="avtar-form" /> */}
              {/* <img src="/logo.png" className="avtar-form" alt="" /> */}
              <img
                src="/mainsIASLogo.jpeg"
                alt=""
                className="avtar-form mb-4"
                style={{ height: '200px' }}
              />
              <br></br>
              <GoogleLogin
                clientId="806236773661-h9u47gufcshp8d2p5fq9v9lio4ig9en2.apps.googleusercontent.com"
                buttonText="Sign In with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
              <h2 className="title avtar-form">Sign Up</h2>
              <div className="col-md-12">
                <FormInput
                  inputMargin="mt-0 mr-2"
                  iconType="fa-user"
                  type="text"
                  placeholder="first name"
                  value={firstName}
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <FormInput
                  inputMargin="mt-0 mr-2"
                  iconType="fa-user"
                  type="text"
                  placeholder="last name"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
                <FormInput
                  border={color}
                  inputMargin="mt-0"
                  iconType=" fa-envelope"
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setLoading(true)
                  }}
                />
                <p className={`text-${color}`}>{EmailCheck}</p>
                <FormInput
                  inputMargin="mb-1"
                  iconType="fa-lock"
                  type="password"
                  placeholder="Password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn form-submit-btn"
                  disabled={isLoading ? true : false}
                >
                  Sign Up
                </button>
              </div>
              <div className="col-md-12">
                <p className="muted-text">
                  By signing up means, You agree with{' '}
                  <a
                    onClick={() => history.push('/user/tnc')}
                    style={{
                      display: 'inline',
                      cursor: 'pointer',
                      color: '#02b290',
                    }}
                  >
                    Terms and Conditions
                  </a>{' '}
                  of the MainsIAS{' '}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isLoading: selectAuthLoading,
})

const mapDispatchToProps = (dispatch) => ({
  signUpStart: (firstName, lastName, email, password) =>
    dispatch(signUpStart({ firstName, lastName, email, password })),
  SocialsignUpStart: (auth_token) =>
    dispatch(SocialsignUpStart({ auth_token })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
