//admin
export const adminSidebarMenu = [
  {
    option: "dashboard",
    name: "Dashboard",
    icon: "fas fa-lg fa-tachometer-alt",
  },
  {
    option: "manager",
    name: "Manager",
    icon: "fas fa-lg fa-user",
  },

  {
    option: "evaluator",
    name: "Evaluator",
    icon: "fas fa-lg fa-user-secret",
  },
  { option: "exam", name: "Exam", icon: "fas fa-lg fa-book-reader" },
  { option: "student", name: "Student", icon: "fas fa-lg fa-user-tie" },
  { option: "promotionalMail", name: "Promotional Mail", icon: "fas fa-lg fa-envelope" },
];

export const adminExamMenu = [
  {
    option: "back",
    name: "Back To Menu",
    icon: "fas fa-lg fa-long-arrow-alt-left",
  },
  {
    option: "examHome",
    name: "Home",
    icon: "fas fa-lg fa-home",
  },

  {
    option: "examProfile",
    name: "Profile",
    icon: "fas fa-lg fa-user",
  },

  {
    option: "examSubscription",
    name: "Subscription",
    icon: "fas fa-lg fa-dollar-sign",
  },
];

//student
export const studentSideMenu = [
  {
    option: "studentHome",
    name: "Home",
    icon: "fas fa-lg fa-home",
  },

  // { option: "exam", name: "Exam", icon: "fas fa-lg fa-book-reader" },
  {
    option: "myanswer",
    name: "My Answers",
    icon: "fas fa-lg fa-file-alt",
    info: "Check the status of your answers"
  },
  {
    option: "mysubscription",
    name: "Submit Now",
    icon: "fas fa-lg fa-file-signature",
    info: "Send your free demo question or subscribed questions from here"
  },
  {
    option: "timer",
    name: "Writing Timer",
    icon: "fa-solid fa-lg fa-clock",
    info: "Use timer before writing answer"
  },
];

export const studentExamMenu = [
  {
    option: "back",
    name: "Back To Menu",
    icon: "fas fa-lg fa-long-arrow-alt-left",
  },
  {
    option: "pricing",
    name: "Pricing",
    icon: "fas fa-lg fa-dollar-sign",
  },
  // {
  //   option: "about",
  //   name: "About",
  //   icon: "fas fa-lg fa-file-alt",
  // },
];

//Manager
export const managerSideMenu = [
  {
    option: "managerDashboard",
    name: "Dashboard",
    icon: "fas fa-lg fa-tachometer-alt",
  },
  { option: "exam", name: "Exam", icon: "fas fa-lg fa-book-reader" },
];

export const managerExamMenu = [
  {
    option: "back",
    name: "Back To Menu",
    icon: "fas fa-lg fa-long-arrow-alt-left",
  },
  {
    option: "examHome",
    name: "Home",
    icon: "fas fa-lg fa-home",
  },

  {
    option: "examSubscription",
    name: "Subscription",
    icon: "fas fa-lg fa-dollar-sign",
  },
];

export const ManagerExamMenuWithoutSubscription = [
  {
    option: "back",
    name: "Back To Menu",
    icon: "fas fa-lg fa-long-arrow-alt-left",
  },
  {
    option: "examHome",
    name: "Home",
    icon: "fas fa-lg fa-home",
  },
];

//Teacher
export const teacherSidebarMenu = [
  {
    option: "teacherDashboard",
    name: "Dashboard",
    icon: "fas fa-lg fa-tachometer-alt",
  },
  {
    option: "glance",
    name: "Payout",
    icon: "fas fa-lg fa-rupee-sign",
  },

  {
    option: "summary",
    name: "Summary",
    icon: "fas fa-lg fa-list-alt",
  },
];
